//import logo from './logo.svg';
import React, { useEffect } from "react";
import './App.css';
import AOS from "aos";
import "aos/dist/aos.css";
function App() {

 
  useEffect(() => {

    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
    });
    
    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }
  
    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      if (all) {
        select(el, all).forEach(e => e.addEventListener(type, listener))
      } else {
        select(el, all).addEventListener(type, listener)
      }
    }
  
    /**
     * Easy on scroll event listener 
     */
    const onscroll = (el, listener) => {
      el.addEventListener('scroll', listener)
    }
  
    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
      let position = window.scrollY + 200
      navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return
        let section = select(navbarlink.hash)
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          navbarlink.classList.add('active')
        } else {
          navbarlink.classList.remove('active')
        }
      })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)
  
    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
      let header = select('#header')
      let offset = header.offsetHeight
  
      if (!header.classList.contains('header-scrolled')) {
        offset -= 10
      }
  
      let elementPos = select(el).offsetTop
      window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
      })
    }
  
    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add('header-scrolled')
        } else {
          selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }
  
    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
      const toggleBacktotop = () => {
        if (window.scrollY > 100) {
          backtotop.classList.add('active')
        } else {
          backtotop.classList.remove('active')
        }
      }
      window.addEventListener('load', toggleBacktotop)
      onscroll(document, toggleBacktotop)
    }
  
    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function(e) {
      select('#navbar').classList.toggle('navbar-mobile')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })
  
    /**
     * Mobile nav dropdowns activate
     */
    on('click', '.navbar .dropdown > a', function(e) {
      if (select('#navbar').classList.contains('navbar-mobile')) {
        e.preventDefault()
        this.nextElementSibling.classList.toggle('dropdown-active')
      }
    }, true)
  
    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
      if (select(this.hash)) {
        e.preventDefault()
  
        let navbar = select('#navbar')
        if (navbar.classList.contains('navbar-mobile')) {
          navbar.classList.remove('navbar-mobile')
          let navbarToggle = select('.mobile-nav-toggle')
          navbarToggle.classList.toggle('bi-list')
          navbarToggle.classList.toggle('bi-x')
        }
        scrollto(this.hash)
      }
    }, true)
  
    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash)
        }
      }
    });
  
    /**
     * Clients Slider
     */

  
    /**
     * Porfolio isotope and filter
     */
 
  
    /**
     * Initiate portfolio lightbox 
     */
  
  
    /**
     * Portfolio details slider
     */
  
  
 
  
    /**
     * Animation on scroll
     */
    function aos_init() {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: true,
        mirror: false
      });
    }
    window.addEventListener('load', () => {
      aos_init();
    });
  
    /**
     * Initiate Pure Counter 
     */
    //new PureCounter();
  }, []);

  return (
    <div >
      <header id="header"  className="header fixed-top">
       <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
        <div  className="logo d-flex">
          <div><img src='/img/logo.png' alt=""/></div>
          <div className="logoname"><span >MIASTL</span><br/><span className="tech">TECHNOLOGY</span></div>
        </div>
        <div>
      <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">About</a></li>
          <li className="dropdown"><a href="#services"><span>Services</span><i className="bi bi-chevron-down"></i></a>
            <ul>
              <li>
                <a href="#">Software Development</a></li>
                <li><a href="#">Cloud IT infrastructure consulting</a> </li>
                  <li> <a href="#">Cyber Security</a> </li>
                    <li> <a href="#">Fintech</a> </li>
                      <li><a href="#">Digital Transformation</a>
              </li>
            </ul>
          </li>
        <li><a className="nav-link scrollto" href="#contact">Contact</a></li>
          <li><a className="getstarted scrollto" href="#about">Get Started</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>

    </div>
  </header>

  <section id="hero" className="hero d-flex align-items-center">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center" style={{marginTop: 15 + 'em'}} >
          <h1 data-aos="fade-up">We offer modern solutions for growing your business</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">At MIASTL We offer end-to-end software development, Consulting, services</h2>
          <div data-aos="fade-up" data-aos-delay="600">
            <div className="text-center text-lg-start">
              <a href="#about" className="btn-get-started scrollto d-inline-flex align-items-center justify-content-center align-self-center">
                <span>Get Started</span>
                <i className="bi bi-arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src='/img/hero-img.png' className="img-fluid" alt=""/>
        </div>
      </div>
    </div>

  </section>
 
  <main id="main">
    {/*!-- About Section--*/}
    <section id="about" className="about">

      <div className="container" data-aos="fade-up">
        <div className="row gx-0">

          <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="200">
            <div className="content">
              <h3>Who We Are</h3>
              <h2>MIASTL is a software development company that specializes in creating innovative solutions for various industries including Banking, Automobile, Healthcare, and Logistics. We are dedicated to providing high-quality, customized software that meets the unique needs of our clients.</h2>
              
                <h3>Mission and Vision:</h3>
                
                <p><i className="bi bi-quote"></i>Our mission is to empower businesses with cutting-edge technology solutions that drive efficiency, productivity, and growth.</p>
                <p><i className="bi bi-quote"></i>We envision a future where technology seamlessly integrates with everyday operations to enhance customer experiences and streamline processes.</p>
              <div className="text-center text-lg-start">
                <a href="#" className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
                  <span>Read More</span>
                  <i className="bi bi-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
            <img src="/img/about.jpg" className="img-fluid" alt=""/>
          </div>

        </div>
      </div>

    </section>{/*End About Section */}  

    {/* ======= Values Section =======*/}
    <section id="values" className="values">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Core Values</h2>
          
        </header>

        <div className="row">

          <div className="col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div className="box">
              <img src='/img/values-2.png' className="img-fluid" alt=""/>
              <h3>Innovation</h3>
              <p>We continuously strive to push boundaries and explore new technologies to deliver the best solutions for our clients.</p>
            </div>
          </div>

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
            <div className="box">
              <img src='/img/values-1.png' className="img-fluid" alt=""/>
              <h3>Collaboration</h3>
              <p>We believe in working closely with our clients to understand their needs and create tailored solutions that exceed expectations.</p>
            </div>
          </div>

         

          <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="600">
            <div className="box">
              <img src='/img/values-3.png' className="img-fluid" alt=""/>
              <h3>Excellence.</h3>
              <p>We are committed to delivering top-notch quality in all our products and services, setting high standards for ourselves and continuously improving.</p>
            </div>
          </div>

        </div>

      </div>

    </section>{/* End Values Section*/}

   

    {/* ======= services Section =======*/}
    <section id="services" className="features">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Services</h2>
        </header>

        <div className="row">

          <div className="col-lg-6">
            <img src='/img/features.png' className="img-fluid" alt=""/>
          </div>

          <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
            <div className="row align-self-center gy-4">

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Consulting</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Integration</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Development </h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Migration</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Security</h3>
                </div>
              </div>

              <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                <div className="feature-box d-flex align-items-center">
                  <i className="bi bi-check"></i>
                  <h3>Digital WorkSpace</h3>
                </div>
              </div>

            </div>
          </div>

        </div> {/* / row*/}


      </div>

    </section>{/* End Features Section*/}

      {/* ======= Clients Section =======*/}
      <section id="clients" className="clients">

        <div className="container" data-aos="fade-up">
  
          <header className="section-header">
            <h2>Our Clients</h2>
             {/*  <p>Temporibus omnis officia</p>*/}
           
          </header>
  
          <div className="clients-slider swiper">
            <div className="swiper-wrapper align-items-center">
              <div className="swiper-slide"><a href="https://tattvafincorp.com"><img src='/img/clients/client-1.png' className="img-fluid" alt=""/></a></div>
              <div className="swiper-slide"><img src='/img/clients/client-2.png' className="img-fluid" alt=""/></div>
              
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
  
      </section>{/* End Clients Section*/}

    <section id="contact" className="contact">

      <div className="container" data-aos="fade-up">

        <header className="section-header">
          <h2>Contact</h2>
          <p>Contact Us</p>
        </header>

        <div className="row gy-4">

          <div className="col-lg-6">

            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p>KRK Nagar, Veerakeralam, Coimbatore - 641007<br/>TamilNadu, India</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+91 9677562086</p>
                  <p>+91 9943874610</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@miastl.com</p>
                  <p><br/></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday<br/>9:00AM - 05:00PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6">
            <form action="forms/contact.php" method="post" className="php-email-form">
              <div className="row gy-4">

                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required/>
                </div>

                <div className="col-md-6 ">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required/>
                </div>

                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required/>
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="6" placeholder="Message" required></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>

                  <button type="submit">Send Message</button>
                </div>

              </div>
            </form>

          </div>

        </div>

      </div>

    </section>{/* End Contact Section*/}

  </main>

  </div>
  );
}

export default App;
